import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-application',{attrs:{"toolbar_title":_vm.$t('practice_results')}},[_c('v-crud',{attrs:{"headers":_vm.headers,"data":_vm.practiceResults,"toolbar_title":_vm.practicesName,"paginationLength":_vm.pagination_length,"page":_vm.page,"detailDialog":_vm.detail_dialog,"no-select":"","no-search":"","no-add":"","isPracticeResult":true,"loading":_vm.loadingData,"dialogWidth":600},on:{"page-changed":_vm.pageChanged,"add-clicked":_vm.addPractices,"edit-clicked":_vm.rowClick,"info-clicked":_vm.resultClick,"restart-clicked":_vm.practiceUserRetryClicked,"cancel-clicked":_vm.cancelClicked,"save-clicked":_vm.confirmClicked},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c(VToolbar,{staticClass:"mt-5",attrs:{"elevation":0}},[_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.detail_dialog = true}}},[_c(VIcon,[_vm._v("mdi-restart")])],1)],1)]},proxy:true},{key:"detail",fn:function(){return [_c(VAutocomplete,{staticClass:"mx-5",attrs:{"width":100,"multiple":"","chips":"","label":_vm.$t('groups'),"items":_vm.groups,"item-text":"name","item-value":"_id","return-object":"","clearable":""},model:{value:(_vm.retryGroups),callback:function ($$v) {_vm.retryGroups=$$v},expression:"retryGroups"}}),_c(VAutocomplete,{staticClass:"mx-5",attrs:{"width":100,"multiple":"","chips":"","label":_vm.$t('users'),"items":_vm.users,"item-text":_vm.getItemText,"item-value":"_id","clearable":""},model:{value:(_vm.retryUserIds),callback:function ($$v) {_vm.retryUserIds=$$v},expression:"retryUserIds"}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }