
<template>
  <v-card
    :elevation="noElevation ? 0 : ''"
    :loading="loading || paginationLoading"
  >
    <v-toolbar tile flat dense>
      <v-toolbar-title v-if="noAdd">
        {{ toolbar_title }}
      </v-toolbar-title>
      <v-btn
        v-else-if="currentRole[addRole]"
        text
        color="primary"
        @click="add"
        >{{ $t(addName) }}</v-btn
      >
      <v-spacer />
      <slot name="toolbar" />
      <!-- <v-btn v-if="selected.length > 0" text color="error" @click="deleteGroup">
        {{ $t("remove") }}
      </v-btn> -->
      <template v-if="!noSearch">
        <v-slide-x-reverse-transition>
          <v-btn icon @click="searchText = !searchText">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <v-text-field
            v-if="searchText"
            v-model="search"
            ref="search_field"
            autofocus
            class="mt-3 pa-0"
            append-icon="mdi-close"
            @click:append="(searchText = false), (search = ''), updateData()"
            @input="updateData()"
            label="Search"
          />
        </v-slide-x-reverse-transition>
      </template>
    </v-toolbar>
    <v-data-table
      v-model="selected"
      item-key="_id"
      :show-select="!noSelect"
      mobile-breakpoint="0"
      :headers="headers"
      :items="data"
      :items-per-page="1000"
      @click:row="rowClicked"
      disable-sort
      :footer-props="footerProps"
      height="780"
    >
      <template v-slot:[`item.startsAt`]="{ item }">
        {{ date_ddmmyy_hhmm(item.startsAt) }}
      </template>
      <template v-slot:[`item.startedAt`]="{ item }">
        {{ date_ddmmyy_hhmm(item.startedAt) }}
      </template>
      <template v-slot:[`item.finishedAt`]="{ item }">
        {{ date_ddmmyy_hhmm(item.finishedAt) }}
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <a v-if="item.phone" :href="'tel:' + item.phone">
          {{ item.phone }}
        </a>
      </template>
      <template v-slot:[`item.user.phone`]="{ item }">
        <a v-if="item.user.phone" :href="'tel:' + item.user.phone">
          {{ item.user.phone }}
        </a>
      </template>

      <template v-slot:[`item.user.name`]="{ item }">
        <div v-if="isPracticeResult && item.user">
          <a
            v-if="item.user.tgUsername"
            target="_blank"
            :href="'https://t.me/' + item.user.tgUsername"
          >
            {{ item.user.name }}
          </a>
          <p v-else class="my-0 py-0">
            {{ item.user.name }}
          </p>
        </div>
        <div v-else>{{ item.user ? item.user.name : "" }}</div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <a
          v-if="item.tgUsername"
          target="_blank"
          :href="'https://t.me/' + item.tgUsername"
          >{{ item.name }}</a
        >
        <p v-else>{{ item.name }}</p>
      </template>
      <template v-slot:[`item.couldStartsAt`]="{ item }">
        {{ date_ddmmyy_hhmm(item.couldStartsAt) }}
      </template>
      <template v-slot:[`item.finishesAt`]="{ item }">
        {{ date_ddmmyy_hhmm(item.finishesAt) }}
      </template>
      <template v-slot:[`item.requestedAt`]="{ item }">
        {{ date_ddmmyy_hhmm(item.requestedAt) }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        {{ $t(item.state) }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ $t(item.type) }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        {{ item.isActive ? "Active" : "Noactive" }}
      </template>
      <template v-slot:[`item.isForGroup`]="{ item }">
        {{ item.isForGroup ? "Active" : "Noactive" }}
      </template>
      <template v-slot:[`item.userIds`]="{ item }">
        {{ item.userIds.length }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-spacer></v-spacer>
        <v-btn
          v-if="currentRole[editRole]"
          icon
          color="primary"
          @click="editClicked(item)"
        >
          <v-icon>mdi-book-edit</v-icon>
        </v-btn>
        <v-btn 
          v-if="currentRole[resultRole]" icon color="primary" @click="infoClicked(item)">
          <v-icon>mdi-table</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.table_actions`]="{ item }">
        <v-spacer></v-spacer>
        <v-btn
          v-if="currentRole[editRole]"
          icon
          color="primary"
          @click="editClicked(item)"
        >
          <v-icon>mdi-book-edit</v-icon>
        </v-btn>
        <v-btn
          v-if="currentRole[deleteRole]"
          icon
          color="primary"
          @click="deleteClicked(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.resultActions`]="{ item }">
        <v-spacer></v-spacer>
        <v-btn
          v-if="item.state == 'finished'"
          icon
          color="primary"
          @click="infoClicked(item)"
        >
          <v-icon>mdi-link-box</v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          @click="restartClicked(item)"
        >
          <v-icon>mdi-restart</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{ item.state == "finished" ? item.total : "" }}
      </template>
      <template v-slot:[`item.right`]="{ item }">
        {{ item.state == "finished" ? item.right : "" }}
      </template>
      <template v-slot:[`item.wrong`]="{ item }">
        {{ item.state == "finished" ? item.wrong : "" }}
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <v-toolbar flat color="transparent">
          <div style="width: 50px; height: 50px">
            <v-img :src="item.tgImage" />
          </div>
        </v-toolbar>
      </template>
    </v-data-table>

    <template>
      <v-dialog v-model="detailDialog" :width="dialogWidth" persistent>
        <v-card :loading="detailDialogLoading" @keyup.enter="enterClicked()">
          <v-card-title
            >{{ cardTitle }}
            <v-spacer />
            <v-btn v-if="noActions" icon @click="cancelClicked">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <slot name="detail" />
          </v-card-text>
          <v-card-actions v-if="!noActions">
            <v-btn
              class="mx-2 mb-2"
              v-if="
                currentRole[deleteRole] &&
                canDelete &&
                currentItem &&
                currentItem._id
              "
              icon
              @click="deleteClicked(currentItem)"
            >
              <v-icon>mdi-delete </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn text @click="cancelClicked">{{ $t("cancel") }}</v-btn>
            <v-btn
              text
              :disabled="saveDisabled"
              color="primary"
              @click="saveClicked"
              >{{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-dialog v-model="deleteDialog" :width="dialogWidth" persistent>
        <v-card>
          <v-card-title
            >{{ $t("are_you_sure") }}
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="deleteDialog = false">{{ $t("cancel") }}</v-btn>
            <v-btn
              text
              color="primary"
              @click="deleteAfterConfirm"
              >{{ $t("confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import Role from "../mixins/role";
export default {
  name: "v-crud",
  props: {
    addName: String,
    headers: Array,
    data: Array,
    page: Number,
    loading: Boolean,
    paginationLength: Number,
    Riders: Boolean,
    Drivers: Boolean,
    paginationLoading: Boolean,
    noPagin: Boolean,
    noSearch: Boolean,
    noSelect: Boolean,
    detailDialog: Boolean,
    cardTitle: String,
    saveDisabled: Boolean,
    canDelete: Boolean,
    dialogWidth: Number,
    noAdd: Boolean,
    toolbar_title: String,
    detailDialogLoading: Boolean,
    Survey: Boolean,
    noActions: Boolean,
    noElevation: Boolean,
    isPracticeResult: Boolean,
    addRole: String,
    editRole: String,
    deleteRole: String,
    resultRole: String,
  },
  mixins: [Role],
  data: () => ({
    currentPage: 1,
    currentItem: null,
    image: "",
    imageDialog: false,
    selected: [],
    playerDialog: false,
    playerLink: "",
    searchText: false,
    search: "",
    footerProps: {
      itemsPerPageOptions: [100, 200, 500],
      showFirstLastPage: true,
      "rows-per-page": 100,
    },
    deleteDialog: false,
  }),
  methods: {
    enterClicked() {
      if (this.saveDisabled) return;
      this.saveClicked();
    },
    updateData() {
      this.$emit("update-data", this.search);
    },
    openLinkClicked(link) {
      this.playerDialog = true;
      this.playerLink = link;
    },
    deleteGroup() {
      const indexes = [];
      for (const s of this.selected) {
        indexes.push(s._id);
      }
      this.$emit("delete-group", indexes);
    },
    rowClicked() {
      // this.currentItem = item;
      // this.$emit("row-click", item);
    },
    editClicked(item) {
      this.$emit("edit-clicked", item);
      this.$emit("row-click", item);
    },
    deleteClicked(item) {
      this.currentItem = item;
      this.deleteDialog = true;
    },
    deleteAfterConfirm() {
      this.$emit("delete-clicked", this.currentItem);
      this.deleteDialog = false;
    },
    infoClicked(item) {
      this.currentItem = item;
      this.$emit("info-clicked", item);
    },
    restartClicked(item) {
      const userId  = item && item.user ? item.user._id:null
      this.$emit("restart-clicked", [userId]);
    },
    add() {
      this.$emit("add-clicked");
    },
    openDialog(imageSrc) {
      if (this.Survey) return;
      this.image = imageSrc;
      this.imageDialog = true;
    },
    saveClicked() {
      this.$emit("save-clicked");
    },
    cancelClicked() {
      this.$emit("cancel-clicked");
    },
  },
  watch: {
    currentPage() {
      this.$emit("page-changed", this.currentPage);
    },
    page() {
      this.currentPage = this.page;
    },
    detailDialog() {
      if (!this.detailDialog) {
        this.currentItem = null;
      }
    },
    selected: {
      deep: true,
      handler() {
        this.$emit("selected", this.selected);
      },
    },
    footerProps: {
      deep: true,
      handler() {
        this.$emit("update-data", this.search);
      },
    },
  },
  mounted() {
    this.currentPage = this.page;
  },
};
</script>
