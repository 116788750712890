<template>
  <v-application :toolbar_title="$t('practice_results')">
    <v-crud :headers="headers" :data="practiceResults" :toolbar_title="practicesName"
      :paginationLength="pagination_length" :page="page" @page-changed="pageChanged" @add-clicked="addPractices"
      @edit-clicked="rowClick" @info-clicked="resultClick" @restart-clicked="practiceUserRetryClicked"
      :detailDialog="detail_dialog" no-select no-search no-add :isPracticeResult="true" :loading="loadingData"
      :dialogWidth="600" @cancel-clicked="cancelClicked" @save-clicked="confirmClicked">
      <template v-slot:toolbar>
        <v-toolbar class="mt-5" :elevation="0">
          <v-spacer />
          <v-btn icon color="primary" @click="detail_dialog = true">
            <v-icon>mdi-restart</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:detail>
        <v-autocomplete class="mx-5" :width="100" multiple chips v-model="retryGroups" :label="$t('groups')"
          :items="groups" item-text="name" item-value="_id" return-object clearable />
        <v-autocomplete class="mx-5" :width="100" multiple chips v-model="retryUserIds" :label="$t('users')"
          :items="users" :item-text="getItemText" item-value="_id" clearable />
      </template>
    </v-crud>
  </v-application>
</template>
  
<script>
import Practices from "../../mixins/practices";
import Users from "../../mixins/user";
import Groups from "../../mixins/group";

export default {
  data: () => ({
    pagination_length: 1,
    page: 1,
    detail_dialog: false,
    practicesName: null,
    practiceDate: null,
    practiceResults: [],
    loadingData: false,
    retryUserIds: [],
    retryGroups: [],
    retryGroupsMap: {}
  }),
  mixins: [Practices, Users, Groups],
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "user.name",
        },
        {
          text: this.$t("phone"),
          value: "user.phone",
        },
        {
          text: this.$t("started_date"),
          value: "startedAt",
        },
        {
          text: this.$t("finished_date"),
          value: "finishedAt",
        },
        {
          text: this.$t("state"),
          value: "state",
        },
        {
          text: this.$t("total"),
          value: "total",
        },
        {
          text: this.$t("right"),
          value: "right",
        },
        {
          text: this.$t("wrong"),
          value: "wrong",
        },
        {
          text: null,
          value: "resultActions",
        },
      ];
    },
    practiceId() {
      return this.$route.query.id;
    },
  },
  watch: {
    page() { },
    detail_dialog() {
      if (!this.detail_dialog) {
        this.cancelClicked();
      }
    },
    retryGroups: {
      deep: true,
      handler() {
        let retryGroupUserIds = []
        for (const key of Object.keys(this.retryGroupsMap)) {
          this.retryGroupsMap[key].isUsed = false
        }
        for (const gr of this.retryGroups) {
          retryGroupUserIds = [
            ...retryGroupUserIds,
            ...gr.userIds
          ]
          this.retryGroupsMap[gr._id] = {
            isUsed: true,
            userIds: gr.userIds
          }
        }
        let removeRetryUserIds = []
        for (const key of Object.keys(this.retryGroupsMap)) {
          if (this.retryGroupsMap[key].isUsed) continue
          removeRetryUserIds = [
            ...removeRetryUserIds,
            ...this.retryGroupsMap[key].userIds
          ]
        }
        removeRetryUserIds = [
          ...new Set(removeRetryUserIds)
        ]

        retryGroupUserIds = [
          ...retryGroupUserIds,
          ...this.retryUserIds,
        ]

        retryGroupUserIds = [
          ...new Set(retryGroupUserIds)
        ]
        // add
        const retryUsersMap = {}
        for (const userId of retryGroupUserIds) {
          retryUsersMap[userId] = true
        }
        // remove
        for (const userId of removeRetryUserIds) {
          delete retryUsersMap[userId]
        }
        const retryUserIds = []
        for (const userId of retryGroupUserIds) {
          if (retryUsersMap[userId]) {
            retryUserIds.push(userId)
          }
        }
        this.retryUserIds = retryUserIds
      }
    }
  },
  methods: {
    getItemText(user) {
      return `${user.name || ""} ${user.login || ""} ${user.phone || ""
        }`.trim();
    },
    pageChanged(page) {
      this.page = page;
    },
    rowClick(item) {
      this.user = this.$copy(item);
      this.$router.push(`/practices/detail?id=${item._id}&step=1`);
    },
    resultClick(item) {
      const link = `https://${process.env.VUE_APP_DOMAIN_PREFIX}1600.satashkent.uz/result?userId=${item.user._id}&practiceId=${item._id}&practiceName=${this.practicesName}&practiceDate=${this.practiceDate}&token=${this.$store.state.token}`;
      window.open(link, "_blank");
    },
    cancelClicked() {
      this.retryUserIds = []
      this.retryGroups = []
      this.detail_dialog = false;
    },
    confirmClicked() {
      this.practiceUserRetryClicked(this.retryUserIds)
      this.detail_dialog = false;
    },
    async practiceUserRetryClicked(userIds) {
      this.retryUserId = []
      this.loadingData = true
      const params = {
        practiceId: this.practiceId,
        userIds: userIds
      }
      const result = await this.practiceUserRetry(params);
      if (result) {
        this.practiceResults = await this.practiceResultGet(this.practiceId);
      }
      this.loadingData = false
    },
    addPractices() {
      this.$router.push(`/practices/detail?step=1`);
    },
  },
  async mounted() {
    this.loadingData = true
    const data = await this.practiceGetById(this.practiceId);
    this.practicesName = data.result.name;
    this.practiceDate = data.result.startsAt;
    this.practiceResults = await this.practiceResultGet(this.practiceId);
    this.loadingData = false
    this.userGetPagin();
    this.groupGetPagin()
  },
};
</script>